import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/Auth.js'
import { useNavigate } from 'react-router-dom';

import './Contact.css';

const Contact = () => {
    
    return (
        <div id='contact'>Contact Information</div>
    );
};

export default Contact;
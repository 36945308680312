import React, { useEffect, useState } from 'react';

import './GitHub.css';

const GitHub = () => {
    
    return (
        <div id='github'>
            <div className='github'>
                This is the github component
            </div>
        </div>
    );
};

export default GitHub;